import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    datainfo:{},
    shopinfo:{
      // 购物信息
      size: "",
      color:0,
    },
    ReceivingInfo:{
      
    },
    openid:null,
    ticket:null,
    spec:{},//选择的规格id
  },
  getters: {
  },
  mutations: {
    getdatainfo(state,data){
      state.datainfo=data
    },
    changeGoods(state,data){
      console.log(data);
      state.shopinfo.size=data.size
      state.shopinfo.color=data.color
    },
    getReceivingInfo(state,data){
      console.log("getReceivingInfo",data);
      state.ReceivingInfo.oneValue=data.oneValue
      state.ReceivingInfo.twoValue=data.twoValue
      state.ReceivingInfo.threeValue=data.threeValue
      state.ReceivingInfo.fourValue=data.fourValue
      state.ReceivingInfo.addressinfo=data.addressinfo
    },
    getOpenTic(state,data){
      state.openid=data.openid
      state.ticket=data.ticket
    },
    getSpec(state,data){
      console.log("datadatadata",data);
      state.spec=data
    }
  },
  actions: {
  },
  modules: {
  }
})
